import * as React from 'react'
import { useForm } from 'react-hook-form'  // validation
import sty from 'src/layouts/styles/index'
import { SubmitMessage } from './SubmitMessage'
import { ExclamationCircle as Warning } from 'src/components/Icons'


const ErrorText = props => <sty.Box {...props}
                                    variant='formValidationError'
                                    children={<><Warning /> {props.children}</>} />


export const ContactForm = () => {

  const [isSuccess, setSuccess] = React.useState(false)
  const [isError, setError] = React.useState(false)

  // Form
  const { register, handleSubmit, errors, reset } = useForm()
  function fetchSubmit(data){
    // With a little help of Hostpoint Support
    let formData = new FormData();
    formData.append("fname", data.fname.trim());
    formData.append("lname", data.lname.trim());
    formData.append("email_text", data.email_text.trim());
    formData.append("message", data.message.trim());
    formData.append("conf", "form_alpisol_conf");

    // Do stuff iff all values are non-empty
    const isNotUndef = (value) => Boolean(value.trim())
    // @ts-ignore
    if ( Array.from(formData.values()).every(isNotUndef) ) {
      fetch("/cgi-sys/FormMail.cgi", {
        method: 'POST',
        body: formData
      })
        .then(() => {
          reset();
          setSuccess(true);
        })
        .catch(error => {
          setError(true);
          console.error('Error:', error);
        });
    }
  }  // form submit function which will invoke after successful validation

  return (
    <>

      <sty.Box
        as='form'
        onSubmit={handleSubmit(fetchSubmit)}
        action="#" // action="/cgi-sys/FormMail.cgi" method="post"
        variant='layoutWrapper'
        sx={{
          my: [6, 8, 8],
          width: [null, null, '80%', '60%'],
        }}>

        {/* FirstName */}
        <sty.Label htmlFor='fname'>
          Vorname
          { errors.fname && <ErrorText>{ errors.fname.message }</ErrorText> }
        </sty.Label>
        <sty.Input
          id='fname'
          name='fname'
          ref={register({
            required: {
              value: true,
              message: 'Dies ist ein Pflichtfeld',
            },
            maxLength: {
              value: 30,
              message: 'Maximal 30 Zeichen erlaubt',
            },
          })}
          type='text'
          autoComplete='given-name'
        />

        {/* LastName */}
        <sty.Label htmlFor='lname'>
          Name
          { errors.lname && <ErrorText>{ errors.lname.message }</ErrorText> }
        </sty.Label>
        <sty.Input
          id='lname'
          name='lname'
          ref={register({
            required: {
              value: true,
              message: 'Dies ist ein Pflichtfeld',
            },
            maxLength: {
              value: 30,
              message: 'Maximal 30 Zeichen erlaubt',
            },
          })}
          type='text'
          autoComplete='family-name'
        />

        {/* Email */}
        <sty.Label htmlFor='email_text'>
          Email
          { errors.email_text && <ErrorText>{ errors.email_text.message }</ErrorText> }
        </sty.Label>
        <sty.Input
          id='email_text'
          name='email_text'
          ref={register({
            required: {
              value: true,
              message: 'Dies ist ein Pflichtfeld',
            },
            maxLength: {
              value: 100,
              message: 'Maximal 100 Zeichen erlaubt',
            },
            pattern: {
              value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}\s*$/i,
              message: 'Ungültiges Email Format',
            },
          })}
          type='text'
          autoComplete='email'
        />

        {/* Message */}
        <sty.Label htmlFor='message'>
          Ihre Nachricht
          { errors.message && <ErrorText>{ errors.message.message }</ErrorText> }
        </sty.Label>
        <sty.Textarea
          rows={5}
          id='message'
          name='message'
          ref={register({
            required: {
              value: true,
              message: 'Dies ist ein Pflichtfeld',
            },
            maxLength: {
              value: 1500,
              message: 'Maximal 1500 Zeichen erlaubt',
            },
          })}
          type='text'
          autoComplete='off'
        />

        <SubmitMessage isSuccess={isSuccess} isError={isError} variant='submitInput' />

        {/* Submit */}
        <sty.LinkButton
          childElementObj={sty.Input}
          variant='submitInput'
          type='submit'
          value='Einreichen'
        />

        <div>
          {/* ConfigurationCall */}
          <sty.Input type="hidden" name="conf" value="form_alpisol_conf" />
        </div>

      </sty.Box>
    </>
  )
}

export default ContactForm

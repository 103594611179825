import * as React from 'react'

import Layout from 'src/layouts/layout'
import sty from 'src/layouts/styles/index'
import { ContentBox, LeftRight } from 'src/components/Main/ContentBox'
import { ContactForm } from 'src/components/Main/ContactForm'


const Embed = props =>
  <sty.Box
    variant='layoutWrapper'
    sx={{
      my: [6, 8, 8],
    }}>
    <LeftRight
      {...props}
      sx={{
        position: 'relative',
        width: '100% !important',
        paddingBottom: ['60%', null, '35%'], // 100:X Aspect Ratio
        '& > iframe': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0, right: 0, top: 0, bottom: 0,
          overflow: 'hidden',
          border: 'none',
          borderRadius: 'small',
        }
      }}
    />
  </sty.Box>


const ContactPage = () => (
  <Layout seo={{ title: 'Kontakt' }}>
    <ContentBox
      src={false}
      title='Kontakt'
      subtitle='Wir sind für Sie da'
      text={<>
        Möchten Sie mehr über uns erfahren?
        <br/>
        Haben Sie Fragen, Wünsche oder Anregungen?
        <br/>
        Gerne helfen wir Ihnen weiter!
      </>}
      button={false}
    />
    <ContactForm />
    <Embed>
      {/* Content Security Policy: Ignoring “'report-sample'” within script-src: ‘strict-dynamic’ specified */}{/* ...and more... */}
      <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB6_ZNpKGmKRdt9wGCTOt5SLIybLvNMv5o&q=Alpisol+Einblasen+GmbH" />
    </Embed>
  </Layout>
)

export default ContactPage

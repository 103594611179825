import * as React from 'react'
import sty from 'src/layouts/styles/index'
import { CheckCircle, TimesCircle } from 'src/components/Icons'


export const SubmitMessage = ({ isSuccess=false, isError=false, ...props }) => {

  const sx = {
    color: isError ? 'error' : 'success',
    p: 5,
  }

  return (
    <>
      <sty.Box {...props}>
        <sty.Flex>
          <sty.Text sx={sx}>
            { isSuccess && <><CheckCircle /> Vielen Dank, wir haben Ihre Anfrage erhalten.</> }
            { isError && <><TimesCircle /> Es ist ein Problem aufgetreten. Bitte versuchen Sie es später erneut.</> }
          </sty.Text>
        </sty.Flex>
      </sty.Box>
    </>
  )
}

export default SubmitMessage
